<template>
    <div id="courseIntroductionDetails">
        <div class="box">
            <div class="breadCrumbs">
                <span style="color: #999999;cursor: pointer;" @click="indexClick">首页</span>>
                <span style="color: #999999;cursor: pointer;" @click="courseClick">精品案例</span>>
                <span style="color: #999999;cursor: pointer;" @click="detailsClick">精品案例详情</span>>
                <span>专业讲堂</span>
            </div>

            <div style="width:100%;height: 1000px;display: flex;justify-content: space-between;">
                <div style="width: 100%; float: left; display: flex;flex-direction: column;align-items: center;">
                    <div style="width:100%;height:50px;background:#fff;color:#76C8F6;text-align:center;line-height:40px;font-size: 18px;font-weight:600">
                        {{caseName}}专业讲堂
                    </div>
                    <div style="width: 100%;">
                        <!-- <img style="width:100%;object-fit: cover;" :src="require('@/assets/img/index/bim.png')" alt="" srcset=""> -->
                        <vue3VideoPlay
                            :autoPlay="false"
                            :src="`${baseUrl}/${csMajor.filePath}`"
                            :controlBtns="['audioTrack', 'quality', 'speedRate', 'volume', 'setting', 'pip', 'fullScreen']"
                        />
                    </div>
                </div>
                <div class="rightBox">
                    <div style="width: 100%;height: 50px;line-height: 50px;text-align: center;border-bottom: 1px solid #F7F8FA;font-weight: bold;color: #333333;">
                        目录
                    </div>
                   <div style="width:100%;height:40px;background:#F7F8FA;margin:10px" v-for="(item,index) in csMajorClass" :key="index">
                        <span style="display:inline-block;width:80px;height:20px;background:#76C8F6;color:#fff;text-align:center;font-size:12px;line-height:20px;border-radius: 7px 7px 7px 0px;float:left">录播</span>
                        <div style="font-size:14px;text-align:center;float:left;padding-top:10px;margin-left:20px;" @click="transmit(item)">{{item.mcrName}} 
                            <img style="vertical-align: middle;cursor: pointer;line-height:0px;float:right;margin-left:10px" :src="require('@/assets/img/courseTechingimg/lubo.png')" alt="">
                        </div>
                   </div>
                   
                </div>
            </div>
        
        </div>
        
    </div>
</template>

<script>
import { reactive, toRefs, onMounted, onUnmounted, computed,watch ,} from "vue";
import 'vue3-video-play/dist/style.css'
import vue3videoPlay from 'vue3-video-play'
import { useRouter } from "vue-router";
import { useStore } from "vuex";
import { getStorage, setStorage } from "@/js/common";
import {backUrl} from "@/js/util";
import {f_get_csMajorClassRoom} from "@/js/homeListApi.js"
import {baseUrl} from '@/js/util.js'
import { ElMessage } from 'element-plus';

export default {
    components: {
        vue3videoPlay
    },
    setup () { 
        const router = useRouter();
        const store = useStore();
        const state = reactive({
            baseUrl:baseUrl,
            userId:getStorage('userId'),
            documentChildren:false,
            id:getStorage('caseSharingId'),
            caseName:getStorage('caseSharingName'),
            csMajorClass:[],
            csMajor:{},

        })
        onMounted(()=>{
            methods.fgetcsMajorClass();
        })
        const methods = {
            //获取专业讲堂
            fgetcsMajorClass(){
                let datas = {
                    caseSharingId:state.id,
                }
                f_get_csMajorClassRoom(datas).then(res => {
                    if (res.data.code == 1) {
                        state.csMajorClass = res.data.data;
                        state.csMajor = state.csMajorClass[0];
                        console.log(state.csMajor,'专业讲堂');
                    }
                })
            },
            //点击切换视频
            transmit(item){
                state.csMajor = item;
            },



            indexClick() {
                store.commit("SetnarbarMenuActive", 1);
                setStorage("narbarMenuActive", 1);
                router.push('/home/index');
            },
            courseClick() {
                router.push('/home/boutiqueCases');
            },
            detailsClick() {
                router.push('/home/casesDetail');
            },
            showChildren() {
                state.documentChildren = !state.documentChildren
            },
        }
        return{
            ...toRefs(state),
            ...methods,
        }
    }
}
</script>
<style  lang="scss" scoped>
#courseIntroductionDetails{
    height: 100%;
    position: relative;
    background: #F7F8FA;
    .box{
        width: 66.5%;
        height: 100%;
        margin: 0 auto;
    }
    .breadCrumbs{
        margin-top: 10px;
        margin-bottom: 10px;
        font-size: 12px;
    }
    .rightBox{
        width:354px;
        height:auto;
        background: #fff;
        float: right;
    }
    
    :deep .el-collapse-item__header{
        background: #F7F8FA;
    }
    :deep .el-collapse-item__wrap{
        border: none;
    }

    .d-player-wrap[data-v-01791e9e] {
        //--primary-color: 64,158,255; 
        width: auto !important ;
        height: auto !important ; 
    }

}
</style>